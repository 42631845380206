import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';

export default class PreloadImage extends BaseComponent<any, any> {
  public constructor(props: any) {
    super(props);
    this.state = {
      imageIsReady: false,
    };
  }

  componentDidMount(): void {
    const img = new Image();
    img.onload = () => this.setState({ imageIsReady: true });
    img.src = this.props.src;
  }

  public render(): ReactNode {
    const { imageIsReady } = this.state;
    const { src, alt, srcset, sizes, loading, width, height } = this.props;
    return imageIsReady ? (
      <img
        srcSet={srcset}
        sizes={sizes}
        src={src}
        alt={alt}
        loading={loading}
        width={width}
        height={height}
      />
    ) : (
      <div className="media media--transparent media--card media--hover-effect c-card__img">
        <div className="loading-overlay__spinner">
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            className="spinner"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeWidth="6"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }
}
